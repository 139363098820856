:root {

  --primary-blue: #0000ff;
  --primary-red: #BB2124;
  --primary-green: #21bb21;
  --primary-yellow: #efe337;
  --primary-orange: #ef7d37;
  --secondary-red: #b83f3f;
  --primary-purple: #c364fa;
  --secondary-purple: #a24ad4;

  --accent: #4469FC;
  --hue-primary-light: #1a73e819;
  --hue-primary-dark: #1a73e83f;

  --text-primary-light: #202124;
  --text-primary-light-dark: #000000;
  --text-primary-dark: #F9F9F9;
  --text-primary-dark-light: #FFFFFF;

  --link-primary-light: #2a2aff;
  --link-primary-light-hover: #1f1fac;
  --link-primary-dark: #58a6ff;
  --link-primary-dark-hover: #91b6c2;

  --text-secondary-light: #545353;
  --text-secondary-dark: #a0a0a0;

  --bg-primary-light: #F9F9F9;
  --bg-primary-dark: #202124;

  --bg-secondary-light: #ebebeb;
  --bg-secondary-dark: #3b3c3e;

  --separator-light: #CCCCCC;
  --separator-dark: #ffffff33;

  --sidebar-active-bg-light: #4469FC33;
  --sidebar-bg-light-hover: #4469FC11;
  --sidebar-active-bg-dark: #4469FC66;
  --sidebar-bg-dark-hover: #4469FC33;

  --btn-bg-primary-light: #202124;
  --btn-bg-primary-light-hover: #666666;
  --btn-bg-primary-light-disabled: #666666;
  --btn-text-primary-light: #EEEEEE;

  --btn-bg-primary-dark: #EEEEEE;
  --btn-bg-primary-dark-hover: #BBBBBB;
  --btn-bg-primary-dark-disabled: #BBBBBB;
  --btn-text-primary-dark: #202124;

  --btn-bg-secondary-light: #4469FC;
  --btn-bg-secondary-light-hover: #3959d7;
  --btn-bg-secondary-light-disabled: #666666;
  --btn-text-secondary-light: #F9F9F9;

  --btn-bg-secondary-dark: #4469FC;
  --btn-bg-secondary-dark-hover: #3959d7;
  --btn-bg-secondary-dark-disabled: #666666;
  --btn-text-secondary-dark: #F9F9F9;

  --btn-bg-tertiary-light: #dcdcdccc;
  --btn-bg-tertiary-light-hover: #DDDDDD;
  --btn-bg-tertiary-light-disabled: #666666;
  --btn-text-tertiary-light: #202124;

  --btn-bg-tertiary-dark: #48484899;
  --btn-bg-tertiary-dark-hover: #333333;
  --btn-bg-tertiary-dark-disabled: #666666;
  --btn-text-tertiary-dark: #F9F9F9;

  --form-text-field-bg-light: #F9F9F9;
  --form-text-field-bg-disabled-light: #EEEEEE;
  --form-text-field-text-light: #202124;
  --form-text-field-border-light: #DDDDDD;

  --form-text-field-bg-dark: #202124;
  --form-text-field-bg-disabled-dark: #333333;
  --form-text-field-text-dark: #F9F9F9;
  --form-text-field-border-dark: #ffffff33;

  --chip-author-bg-light: #94949433;
  --chip-author-bg-dark: #48484899;
  --chip-collection-bg-light: #a02ef233;
  --chip-collection-bg-dark: #a02ef266;
  --chip-mode-bg-light: #2ef2c166;
  --chip-mode-bg-dark: #2ef2c166;
  --chip-model-bg-light: #f2b42e66;
  --chip-model-bg-dark: #f2b42e66;
  --chip-prompt-bg-light: #2ed8f233;
  --chip-prompt-bg-dark: #2ed8f299;

}

.light-theme {

  --text-primary: var(--text-primary-light);
  --text-secondary: var(--text-secondary-light);
  --text-primary-emphasized: var(--text-primary-light-dark);

  --link-primary: var(--link-primary-light);
  --link-primary-hover: var(--link-primary-light-hover);
  
  --bg-primary: var(--bg-primary-light);
  --bg-secondary: var(--bg-secondary-light);

  --hue-primary: var(--hue-primary-light);
  --separator: var(--separator-light);

  --sidebar-active-bg: var(--sidebar-active-bg-light);
  --sidebar-bg-hover: var(--sidebar-bg-light-hover);

  --btn-bg-primary: var(--btn-bg-primary-light);
  --btn-bg-primary-hover: var(--btn-bg-primary-light-hover);
  --btn-bg-primary-disabled: var(--btn-bg-primary-light-disabled);
  --btn-text-primary: var(--btn-text-primary-light);

  --btn-bg-secondary: var(--btn-bg-secondary-light);
  --btn-bg-secondary-hover: var(--btn-bg-secondary-light-hover);
  --btn-bg-secondary-disabled: var(--btn-bg-secondary-light-disabled);
  --btn-text-secondary: var(--btn-text-secondary-light);

  --btn-bg-tertiary: var(--btn-bg-tertiary-light);
  --btn-bg-tertiary-hover: var(--btn-bg-tertiary-light-hover);
  --btn-bg-tertiary-disabled: var(--btn-bg-tertiary-light-disabled);
  --btn-text-tertiary: var(--btn-text-tertiary-light);

  --form-text-field-bg: var(--form-text-field-bg-light);
  --form-text-field-bg-disabled: var(--form-text-field-bg-disabled-light);
  --form-text-field-text: var(--form-text-field-text-light);
  --form-text-field-border: var(--form-text-field-border-light);

  --chip-author-bg: var(--chip-author-bg-light);
  --chip-collection-bg: var(--chip-collection-bg-light);
  --chip-mode-bg: var(--chip-mode-bg-light);
  --chip-model-bg: var(--chip-model-bg-light);
  --chip-prompt-bg: var(--chip-prompt-bg-light);

}

.dark-theme {

  --text-primary: var(--text-primary-dark);
  --text-secondary: var(--text-secondary-dark);
  --text-primary-emphasized: var(--text-primary-dark-light);

  --link-primary: var(--link-primary-dark);
  --link-primary-hover: var(--link-primary-dark-hover);
  
  --bg-primary: var(--bg-primary-dark);
  --bg-secondary: var(--bg-secondary-dark);

  --separator: var(--separator-dark);
  --hue-primary: var(--hue-primary-dark);

  --sidebar-active-bg: var(--sidebar-active-bg-dark);
  --sidebar-bg-hover: var(--sidebar-bg-dark-hover);

  --btn-bg-primary: var(--btn-bg-primary-dark);
  --btn-bg-primary-hover: var(--btn-bg-primary-dark-hover);
  --btn-bg-primary-disabled: var(--btn-bg-primary-dark-disabled);
  --btn-text-primary: var(--btn-text-primary-dark);

  --btn-bg-secondary: var(--btn-bg-secondary-dark);
  --btn-bg-secondary-hover: var(--btn-bg-secondary-dark-hover);
  --btn-bg-secondary-disabled: var(--btn-bg-secondary-dark-disabled);
  --btn-text-secondary: var(--btn-text-secondary-dark);

  --btn-bg-tertiary: var(--btn-bg-tertiary-dark);
  --btn-bg-tertiary-hover: var(--btn-bg-tertiary-dark-hover);
  --btn-bg-tertiary-disabled: var(--btn-bg-tertiary-dark-disabled);
  --btn-text-tertiary: var(--btn-text-tertiary-dark);

  --form-text-field-bg: var(--form-text-field-bg-dark);
  --form-text-field-bg-disabled: var(--form-text-field-bg-disabled-dark);
  --form-text-field-text: var(--form-text-field-text-dark);
  --form-text-field-border: var(--form-text-field-border-dark);

  --chip-author-bg: var(--chip-author-bg-dark);
  --chip-collection-bg: var(--chip-collection-bg-dark);
  --chip-mode-bg: var(--chip-mode-bg-dark);
  --chip-model-bg: var(--chip-model-bg-dark);
  --chip-prompt-bg: var(--chip-prompt-bg-dark);

}

@font-face {
  font-family: 'Inter Regular';
  src: local('Inter Regular'), url(./fonts/Inter/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'InterDisplay Regular';
  src: local('InterDisplay Regular'), url(./fonts/Inter/InterDisplay-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter Medium';
  src: local('Inter Medium'), url(./fonts/Inter/Inter-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'InterDisplay Medium';
  src: local('InterDisplay Medium'), url(./fonts/Inter/InterDisplay-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter Bold';
  src: local('Inter Bold'), url(./fonts/Inter/Inter-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter SemiBoldItalic';
  src: local('Inter SemiBoldItalic'), url(./fonts/Inter/Inter-SemiBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Mono';
  src: local('Roboto Mono'), url(./fonts/Roboto/RobotoMono.ttf) format('truetype');
}

@font-face {
  font-family: 'HankenGrotesk Regular';
  src: local('HankenGrotesk Regular'), url(./fonts/Hanken/HankenGrotesk-Regular.ttf) format('truetype');
}

