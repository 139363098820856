html {
  font-size: 100%;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  // scrollbar-width: 4px;
  // overflow: hidden;
}

h1 {
  font-family: 'InterDisplay Medium', sans-serif;
  color: var(--text-primary);
  font-size: 3.6rem;
  // line-height: 3.4rem;
  letter-spacing: -1px;
}

h2 {
  font-family: 'InterDisplay Medium', sans-serif;
  color: var(--text-primary);
  font-size: 1.8rem;
  letter-spacing: -1px;
}

h3 {
  font-family: 'InterDisplay Medium', sans-serif;
  color: var(--text-primary);
  font-size: 1.1rem;
}

h4 {
  font-family: 'InterDisplay Medium', sans-serif;
  color: var(--text-primary);
  font-size: 1rem;
}

h5 {
  font-family: 'InterDisplay Medium', sans-serif;
  color: var(--text-primary);
  font-size: 0.9rem;
}


h6 {
  font-family: 'Inter Medium', sans-serif;
  color: var(--text-secondary);
  font-size: 0.8rem;
  text-transform: uppercase;
  // text-decoration: underline solid var(--primary-purple);
}

body, p {
  font-family: 'Inter Regular', sans-serif;
  font-size: 0.85rem;
  // line-height: 1.2rem;
  margin-bottom: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-primary);
}

@media screen and (max-width: 840px) {
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 1.3rem;
  }
  h4 {
    font-size: 1rem;
  }
}

.large {
  font-size: 110%;
}

.small {
  font-size: 85%;
}

.tiny {
  font-size: 65%;
}

.micro {
  font-size: 50%;
}

.bold {
  font-family: "Inter Bold";
}

.muted {
  color: var(--text-secondary);
}

.danger {
  color: var(--primary-red);
}

.ai {
  color: var(--primary-purple);
}

a {
  color: var(--link-primary);
}

a:hover {
  color: var(--link-primary-hover);
  text-decoration: none;
}

.link {
  color: var(--link-primary);
}

.citation {
  color: var(--link-primary);
  background-color: var(--bg-secondary);
  padding: 2px 4px;
}

ul {
  padding: 5px 0px;
  margin-bottom: 0.7rem;
}

ol {
  padding: 5px 0px;
  margin-bottom: 0.7rem;
}

ul li {
  color: var(--text-primary);
  margin-left: 12px;
  padding-left: 3px;
  // margin-bottom: 0.4rem;
}

ol li {
  color: var(--text-primary);
  margin-left: 12px;
  padding-left: 3px;
  // margin-bottom: 0.4rem;
}

.link:hover {
  color: var(--link-primary-hover);
  text-decoration: none;
  cursor: pointer;
}

// .embedded-html {
//   font-family: 'Inter Regular', sans-serif;
//   font-size: 0.85rem;
//   margin-bottom: auto;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   color: var(--text-primary);
// }

.img-thumbnail {
  background-color: transparent;
  border: 2px solid var(--text-secondary);
  padding: 1px;
}

.auth-pattern {
  background-color: var(--bg-primary);
  opacity: 0.5;
  background-image:  linear-gradient(#4469FC 2px, transparent 2px), linear-gradient(90deg, #4469FC 2px, transparent 2px), linear-gradient(#4469FC 1px, transparent 1px), linear-gradient(90deg, #4469FC 1px, var(--bg-primary) 1px);
  background-size: 50px 50px, 50px 50px, 10px 10px, 10px 10px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
}

pre {
  // font-family: 'Roboto Mono', sans-serif;
  // font-size: 0.75rem;
  // color: var(--text-secondary);
  // background-color: var(--bg-code);
  padding: 5px;
  // margin-top: 10px;
  // overflow-x: auto;
  // white-space: pre-wrap;
  // white-space: -moz-pre-wrap;
  // white-space: -pre-wrap;
  // white-space: -o-pre-wrap;
  // word-wrap: break-word;
}

hr {
  background-color: var(--separator);
  border: none;
  height: 1px;
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.hovering-row:hover {
  background-color: var(--bg-primary-hover);
}

.hovering-hidden {
  visibility: hidden;
}

.hovering-trigger:hover > .hovering-hidden {
  visibility: visible;
}

.scrollable-element {
  scrollbar-width: thin;
  scrollbar-color: var(--separator) transparent;
}

::-webkit-scrollbar{
  overflow:visible;
  width:4px;
}

::-webkit-scrollbar-track{
  background-clip:padding-box;
}

::-webkit-scrollbar-thumb{ 
  background-color: var(--separator);
  height: 30px;
}

code {
  color: var(--text-secondary);
  background-color: none;
}

/*
  Icon
*/

.assistant-icon {
  padding: 6px;
  color: var(--text-primary);
  background-color: var(--bg-secondary);
}

/*
  LLM responses
*/


.llm ul li {
  color: var(--text-primary);
  margin-left: 15px;
  padding-left: 3px;
  margin-bottom: 0.2rem;
}

.llm ol li {
  color: var(--text-primary);
  margin-left: 15px;
  padding-left: 3px;
  margin-bottom: 0.2rem;
}

/* 
  Navbar 
*/

.nav-link {
  font-family: 'Inter Medium', sans-serif;
  color: var(--text-primary);
  text-transform: capitalize;
}

.nav-link:active {
  color: var(--text-primary-emphasized);
}

.dropdown-menu {
  background-color: var(--bg-primary);
  border-color: var(--separator);
}

.dropdown-divider {
  border-color: #CCCCCC;
}

.dropdown-item {
  // font-family: 'Inter Regular', sans-serif;
  padding: 4px 10px !important;
  color: var(--text-primary);
  font-size: 0.8rem;
}

.dropdown-item:hover {
  background-color: transparent;
  color: var(--text-secondary);
}

/*
  Table
*/

table {
  // border: 1px solid var(--separator);
  margin-top: 10px;
  margin-bottom: 10px;
}

td {
  border: 1px solid var(--separator)!important;
  color: var(--text-primary);
  font-family: 'Inter Regular', sans-serif;
  font-size: 0.8rem;
  padding: 8px;
}

th {
  color: var(--text-primary);
  border: 1px solid var(--separator)!important;
  font-family: 'Inter Medium', sans-serif;
  font-size: 0.8rem;
  padding: 8px;
}

/*
  Buttons
*/

.btn {
  font-family: 'Inter Medium', sans-serif;
  font-size: 0.75rem;
  padding: 4px 14px;
  border-radius: 5px;
  border-width: 0px;
  background-clip:  padding-box;
}

.btn-primary {
  font-size: 0.85rem;
  padding: 8px 18px;
  color: var(--btn-text-primary) !important;
  background-color: var(--btn-bg-primary) !important;
  border: 2px solid var(--btn-bg-primary) !important;
}

.btn-primary:hover {
  background-color: var(--btn-bg-primary-hover) !important;
  border: 2px solid var(--btn-bg-primary-hover) !important;
}

.btn-primary:disabled {
  background-color: var(--btn-bg-primary-disabled) !important;
  border: 2px solid var(--btn-bg-primary-disabled) !important;
}

.btn-secondary {
  color: var(--btn-text-secondary); 
  background-color: var(--btn-bg-secondary); 
  border: 1px solid var(--btn-border-secondary);
}

.btn-secondary:hover {
  background-color: var(--btn-bg-secondary-hover);
  border: 1px solid var(--btn-bg-secondary-hover);
}

.btn-secondary:disabled {
  background-color: var(--btn-bg-secondary-disabled); 
  border: 1px solid var(--btn-bg-secondary-disabled); 
}

.btn-tertiary {
  color: var(--btn-text-tertiary) !important;
  background-color: var(--btn-bg-tertiary) !important;
  border: 1px solid var(--btn-border-tertiary) !important;
}

.btn-tertiary:hover {
  background-color: var(--btn-bg-tertiary-hover) !important;
  border: 1px solid var(--btn-border-tertiary) !important;
}

.btn-tertiary:disabled {
  background-color: var(--btn-bg-tertiary-disabled) !important;
  border: 1px solid var(--btn-border-tertiary) !important;
}

.btn-danger {
  color: var(--btn-text-danger) !important;
  background-color: var(--btn-bg-danger) !important;
  border: 2px solid var(--btn-border-danger) !important;
}

.btn-danger:hover {
  background-color: var(--btn-bg-danger) !important;
}

.btn-link {
  // color: var(--link-primary)!important;
  padding: 0px !important;
  border: none !important;
  border-radius: 0;
  background-color: none !important;
  text-transform: none !important;
  text-decoration: none !important;
}

.btn-link:hover {
  color: var(--link-primary-hover) !important;

}

.btn-group {
  border: none;
  // border-radius: 10px;
}

.btn-group .btn {
  padding: 4px 10px !important;
  // border-radius: 10px;
}

.btn-on {
  padding: 4px 12px !important;
  color: #EEEEEE !important;
  background-color: #138f70 !important;
  border: 2px solid #138f70 !important;
}

.btn-off {
  padding: 4px 12px !important;
  color: #666666 !important;
  background-color: #EEEEEE !important;
  border: 2px solid #EEEEEE !important;
}

.btn-off-reversed {
  padding: 4px 12px !important;
  color: #EEEEEE !important;
  background-color: #666666 !important;
  border: 2px solid #666666 !important;
}

.btn-icon {
  color: var(--btn-text-tertiary) !important;
  background-color: var(--btn-bg-tertiary) !important;
  border: none!important;
  padding: 4px 8px;
}


/*
  Card
*/

.card {
  background-color: var(--bg-primary);
  border: 1px solid var(--separator);
  // width: 300px;
  padding: 18px;
  border-radius: 3px;
}

.card-code {
  background-color: #EEEEEE;
}

/*
  Form
*/

.form-control {
  background-color: var(--form-text-field-bg);
  font-size: 0.8rem;
  height: 36px;
  color: var(--form-text-field-text);
  border: 1px solid var(--form-text-field-border);
  border-radius: 3px;
  -webkit-appearance: none;
  outline: none!important;
  -webkit-box-shadow: none!important;
  -moz-box-shadow: none!important;
  box-shadow: none!important;
}

.form-control:focus {
  color: var(--form-text-field-text);
  background-color: var(--form-text-field-bg);
  outline: 1px solid var(--accent);
}

.form-control:disabled {
  background-color: var(--form-text-field-bg-disabled);
  color: var(--form-text-field-text-disabled);
}

.form-check-inline .form-check-input {
}

.form-label {
  margin: 0px;
  margin-bottom: 3px;
  color: var(--text-primary-emphasized);
  font-size: 0.8rem;
  font-family: 'Inter Medium';
}

.form-check-label {
  font-size: 0.8rem;
}

.form-text {
  font-size: 0.75rem;
  // margin-bottom: 5px;
}

thead {
  font-size: 0.8rem;
}

select {
  // background-color: var(--form-text-field-bg);
  background: transparent;
  color: var(--form-text-field-text);
  border: 1px solid var(--form-text-field-border);
  border-radius: 3px;
  padding: 8px;
  font-size: 0.8rem;
  width: 100%;
  height: 36px;
  outline: none!important;
  -webkit-box-shadow: none!important;
  -moz-box-shadow: none!important;
  box-shadow: none!important;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  // background-repeat: no-repeat;
  // background-position-x: 21px;
  // background-position-y: 0px;
  // background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+);
}

.form-control[readonly] {
  background-color: var(--form-text-field-bg-disabled);
}

.spinner-border {
  color: var(--separator);
}

textarea {
  background-color: var(--form-text-field-bg);
  color: var(--form-text-field-text);
  resize:none!important;
  outline: none!important;
  -webkit-box-shadow: none!important;
  -moz-box-shadow: none!important;
  box-shadow: none!important;
}

input {
  background-color: var(--form-text-field-bg);
  color: var(--form-text-field-text);
  // background-color: #EEEEEE;
  // color: #000000;
  border: none;
  padding-top: 2px;
  padding-bottom: 2px;
  outline: none!important;
  -webkit-box-shadow: none!important;
  -moz-box-shadow: none!important;
  box-shadow: none!important;
}

input[type="search"]::-webkit-search-cancel-button {
  // -webkit-appearance: none;
  height: 10px;
  width: 10px;
  color: var(--text-primary);
  // background: var(--text-primary);
}

input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-year-field:focus {
    background-color: var(--accent);
    color: var(--form-text-field-text);
    outline: none;
    cursor: auto;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.display-linebreak {
  white-space: pre-line;
}

.editable-input {
  padding: 6px;
  background-color: var(--form-text-field-bg);
  cursor: pointer;
  border: none;
}

.editable-input:focus {
  background-color: var(--form-text-field-bg);
  cursor: auto;
}

.editable-input:hover {
  background-color: var(--form-text-field-bg);
  cursor: auto;
}

/*
  Badge
*/

.badge {
  padding: 6px 12px;
  font-family: 'Inter Regular', sans-serif;
  text-transform: uppercase;
  // line-height: 0px;
  font-size: 0.65rem;
  color: var(--text-primary);
  border-radius: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-success {
  background-color: var(--badge-success-bg)!important;
  border: 1px solid var(--badge-success-border)!important;
}

.bg-chip-author {
  background-color: var(--bg-secondary);
  text-transform: none;
  padding: 4px 10px;
  font-size: 0.75rem;
}

.bg-chip-tag {
  background-color: var(--bg-secondary);
  text-transform: none;
  padding: 4px 10px;
  font-size: 0.75rem;
}

/*
  Pill
*/

.pill-tertiary {
  font-size: 0.9rem;
  border-radius: 16px;
  padding: 0px 8px !important;
  background-clip:  padding-box;
  color: var(--btn-text-tertiary) !important;
  background-color: var(--btn-bg-tertiary) !important;
  border: 2px solid var(--btn-bg-tertiary) !important;
  cursor: pointer;
}

.pill-tertiary:hover {
  background-color: var(--btn-bg-tertiary-hover) !important;
  border: 2px solid var(--btn-bg-tertiary-hover) !important;
}

.pill-tertiary:disabled {
  background-color: var(--btn-bg-tertiary-disabled) !important;
  border: 2px solid var(--btn-bg-tertiary-disabled) !important;
}


/*
  Navigation tabs
*/

.nav-tabs {
  color: var(--text-primary);
  background-color: none;
  // font-size: 0.8rem;
  padding: 0px;
}

.nav-tabs .nav-link {
  font-family: 'Inter Regular', sans-serif;
  background-color: #DDDDDD;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid #DDDDDD;
  margin-right: 1px;
}

.nav-tabs .nav-link:hover {
  color: #000000;
  background-color: #EEEEEE;
  border: 1px solid #DDDDDD;
}

.nav-tabs .nav-link.active {
  font-family: 'Inter Medium', sans-serif;
  background-color: #FFFFFF;
  color: #000000;
  border: 1px solid #CCCCCC;
}

// .nav-tabs .nav-link.active:hover {
//   background-color: #999999;
// }

/*
  Navigation pills
*/

.nav-pills .nav-link.active {
  background-color: #222222; // #050A30;
  color: #EEEEEE;
  border-bottom: 2px solid #333333;
  border-radius: 0px;
  margin-right: 10px;
  margin-top: 10px;
  padding: 8px 20px;
}

.nav-pills .nav-link {
  background-color: #000000; // #050A30;
  border: none;
  border-radius: 0px;
  margin-right: 10px;
  margin-top: 10px;
  padding: 8px 20px;
}

// .nav-link:pauseOnHover {
//   background-color: blue;
// }

/*
  Navigation underline
*/

.nav-underline .nav-link {
  color: var(--text-secondary);
}

.nav-underline .nav-link.active {
  color: var(--text-primary);
  border-bottom: 2px solid var(--separator);
}

.nav-underline .nav-link:hover {
  color: var(--text-primary);
}

/* Modal */

.modal {
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.modal-content {
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.modal-body {
  border: none;
  border-radius: 3px;
  padding: 20px;
}

.modal-title {
  font-size: 0.9rem;
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-header {
  padding: 10px;
}

.modal-dark {
  background-color: var(--bg-primary-dark);
  color: var(--text-primary-dark);
  scrollbar-color: var(--bg-primary-dark) var(--bg-primary-dark);
  border: 1px solid var(--separator-dark);
}

.modal-light {
  background-color: var(--bg-primary-light);
  color: var(--text-primary-light);
  scrollbar-color: var(--bg-primary-light) var(--bg-primary-light);
  border: 1px solid var(--separator-light);
}

.modal-dark .muted {
  color: var(--text-secondary-dark);
}

.modal-light .muted {
  color: var(--text-secondary-light);
}

.modal-dark .form-control {
  border: 1px solid var(--form-text-field-border-dark);
}

.modal-light .form-control {
  border: 1px solid var(--form-text-field-border-light);
}

.modal-dark select {
  border: 1px solid var(--form-text-field-border-dark);
}

.modal-light select {
  border: 1px solid var(--form-text-field-border-light);
}

.modal-dark .btn-primary {
  background-color: var(--btn-bg-primary-dark)!important;
  color: var(--btn-text-primary-dark)!important;
}

.modal-light .btn-primary {
  background-color: var(--btn-bg-primary-light)!important;
  color: var(--btn-text-primary-light)!important;
}

.modal-dark .btn-primary:hover {
  background-color: var(--btn-bg-primary-dark-hover);
}

.modal-light .btn-primary:hover {
  background-color: var(--btn-bg-primary-light-hover);
}

.modal-dark .btn-primary:disabled {
  background-color: var(--btn-bg-primary-dark-disabled);
}

.modal-light .btn-primary:disabled {
  background-color: var(--btn-bg-primary-light-disabled);
}

.modal-dark .btn-secondary {
  background-color: var(--btn-bg-secondary-dark)!important;
  color: var(--btn-text-secondary-dark)!important;
}

.modal-light .btn-secondary {
  background-color: var(--btn-bg-primary-light)!important;
  color: var(--btn-text-primary-light)!important;
}

.modal-dark .btn-secondary:hover {
  background-color: var(--btn-bg-secondary-dark-hover);
}

.modal-light .btn-secondary:hover {
  background-color: var(--btn-bg-secondary-light-hover);
}

.modal-dark .btn-secondary:disabled {
  background-color: var(--btn-bg-secondary-dark-disabled);
}

.modal-light .btn-secondary:disabled {
  background-color: var(--btn-bg-secondary-light-disabled);
}

.modal-dark .btn-tertiary {
  background-color: var(--btn-bg-tertiary-dark)!important;
  color: var(--btn-text-tertiary-dark)!important;
}

.modal-light .btn-tertiary {
  background-color: var(--btn-bg-tertiary-light)!important;
  color: var(--btn-text-tertiary-light)!important;
}

.modal-dark .btn-tertiary:hover {
  background-color: var(--btn-bg-tertiary-hover-dark) !important;
}

.modal-light .btn-tertiary:hover {
  background-color: var(--btn-bg-tertiary-hover-light) !important;
}

.modal-dark .btn-tertiary:disabled {
  background-color: var(--btn-bg-tertiary-dark-disabled) !important;
}

.modal-light .btn-tertiary:disabled {
  background-color: var(--btn-bg-tertiary-light-disabled) !important;
}

.modal-dark .card {
  background-color: var(--bg-primary-dark);
  border: 1px solid var(--separator-dark);
}

.modal-light .card {
  background-color: var(--bg-primary-light);
  border: 1px solid var(--separator-light);
}

.modal-dark .link {
  color: var(--link-primary-dark);
}

.modal-light .link {
  color: var(--link-primary-light);
}

.modal-dark .separator {
  color: var(--separator-dark);
}

.modal-light .separator {
  color: var(--separator-light);
}

.modal-dark .hovering-row:hover {
  background-color: var(--bg-primary-hover-dark);
}

.modal-light .hovering-row:hover {
  background-color: var(--bg-primary-hover-light);
}

.modal-dark .suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid var(--separator);
  background-color: var(--bg-primary-dark);
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 100;
}

.modal-light .suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid var(--separator);
  background-color: var(--bg-primary-light);
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 100;
}

.modal-dark .bg-chip-author {
  background-color: var(--bg-secondary-dark);
}

.modal-light .bg-chip-author {
  background-color: var(--bg-secondary-light);
}

.modal-dark .bg-chip-tag {
  background-color: var(--bg-secondary-dark);
}

.modal-light .bg-chip-tag {
  background-color: var(--bg-secondary-light);
}

/*
  Accordion
*/

.accordion {
  background-color: none;
}

/* 
 Pro React Sidebar
*/

.ps-sidebar-root {
  border-width: 0px;
}

.ps-menuitem-root {
  margin-left: 0px;
}

// .ps-submenu-content {
//   z-index: 1000;
// }

/* 
  Toast
*/

.toast {
  font-family: 'Inter Regular', sans-serif;
  // font-size: 1em;
  // background: #F9F9F9;
}

/*
  Independent scrolling content divs
*/

.independent-scrolling {
  height: 100vh;
  overflow: auto;
}

/*
  AG Grid
*/


.ag-theme-alpine-light {
  // --ag-font-size: 0.75rem;
  --ag-foreground-color: var(--text-primary);
  --ag-borders: none;
  --ag-row-border-color: var(--separator);
  --ag-background-color: var(--bg-primary);
  --ag-icon-font-family: 'agGridAlpine';
  --ag-selected-row-background-color: var(--bg-secondary);
}

.ag-theme-alpine-dark {
  // --ag-font-size: 0.75rem;
  --ag-foreground-color: var(--text-primary);
  --ag-borders: none;
  --ag-row-border-color: var(--separator);
  --ag-background-color: var(--bg-primary);
  --ag-icon-font-family: 'agGridAlpine';
  --ag-selected-row-background-color: var(--bg-secondary);
}

.ag-theme-alpine-light .ag-header {
  font-family: 'Inter Medium';
  background-color: transparent;
}

.ag-theme-alpine-dark .ag-header {
  font-family: 'Inter Medium';
  background-color: transparent;
}

.ag-theme-alpine-light .ag-cell-value {
  line-height: 22px !important;
  word-break: normal;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ag-theme-alpine-dark .ag-cell-value {
  line-height: 22px !important;
  word-break: normal;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ag-row-odd {
  background-color: transparent;
}

.ag-row:hover {
  // background-color: var(--bg-secondary);
  cursor: pointer;
}

.ag-theme-alpine-light .ag-header {
  font-family: 'Inter Medium';
  // font-size: 0.8rem;
  // text-transform: capitalize;
  background-color: transparent;
}

.ag-theme-alpine-light .ag-cell-value {
  line-height: 22px !important;
  word-break: normal;
  padding-top: 5px;
  padding-bottom: 5px;
}

/*
  Color swatches
*/

.swatch-color {
  width: 36px;
  height: 14px;
  border-radius: 2px;
  // background-color: yellow;
}

.swatch-component {
  padding: 2px;
  background-color: #FFFFFF;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  display: inline-block;
  cursor: pointer;
}

.swatch-popover {
  position: absolute;
  z-index: 2;
}

.swatch-cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

/*
  EditorJS
*/

/* Editor: Set max width of block */
.ce-block__content, 
.ce-toolbar__content {
  width: '100%';
}

.ce-block {
  padding-left: 2px;
  padding-right: 2px;
}

.ce-toolbar__plus,
.ce-toolbar__settings-btn,
.ce-paragraph {
  color: var(--text-primary) !important;
}
.ce-toolbar__plus:hover,
.ce-toolbar__settings-btn:hover {
  color: var(--bg-secondary) !important;
}

.ce-toolbox, .ce-settings {
  background-color: transparent;
}

/* Image tool: Disable caption */
.image-tool__caption { display: none; }

/* Image tool: Disable settings */
.ce-popover-item[data-item-name="withBorder"],
.ce-popover-item[data-item-name="withBackground"],
.ce-popover-item[data-item-name="stretched"] {
  display: none;
}

.editorjs-link {
  color: var(--link-primary);
  background-color: var(--hue-primary);
  padding: 1px 4px;
  cursor: pointer;
}

.anyButtonContainer__registerButton {
  font-family: 'Inter Bold', sans-serif !important;
  font-size: 0.8rem !important;
  text-transform: uppercase !important;
  padding: 4px 12px !important;
  color: #FFFFFF !important;
  background-color: #1a73e8 !important;
}

/*
  CSV Importer
*/

.CSVImporter_FileSelector {
  border: 1px dashed var(--separator);
  padding: 4em;
  border-radius: .4em;
  background: #f0f0f0;
  text-align: center;
  color: #202020;
  cursor: pointer
}

.CSVImporter_FileStep__header {
  font-size: 1em;
}

.CSVImporter_Importer {
  background-color: #F9F9F9;
  border: 1px solid #DDDDDD;
}

.CSVImporter_FormatRawPreview__scroll {
  background-color: #EEEEEE;
  font-size: 0.8em;
}

.CSVImporter_TextButton {
  padding: 4px 12px !important;
  color: #FFFFFF !important;
  background-color: #1a73e8 !important;
  border: 2px solid #1a73e8 !important;
}

.CSVImporter_TextButton:hover {
  background-color: #1d5b9a !important;
  border: 2px solid #1d5b9a !important;
}

.CSVImporter_ColumnDragSourceArea__box {
  background-color: #F9F9F9;
}

/*
  Chatbot
*/

.chat-messages {
  display: flex;
  position: fixed;
  flex-direction: column-reverse;
  overflow-x: hidden;
  overflow-y: auto;
}

.chat-messages-library {
  display: flex;
  position: fixed;
  flex-direction: column-reverse;
  overflow-x: hidden;
  overflow-y: auto;
}

.chat-messages-library::-webkit-scrollbar {
  scrollbar-width: 0px;
  // color: transparent;
  scrollbar-color: var(--bg-primary) var(--bg-primary);
}

.chat-box {
  position: relative;
  bottom: 20px;
  border-radius: 3px;
  border: 1px solid var(--separator);
  // box-shadow: 3px 3px var(--bg-secondary);
}

.chat-box:focus-within {
  outline: 1px solid var(--accent)!important;
}

.chat-container {
  width: 100%;
  height: 100vh;
  // background-color: lightgrey;
}

.chat-fixed-element {
  position: fixed;
  bottom: 0px;
  padding: 10px;
  width: calc(80% - 0px);
}

/*
  Search box
*/

.search-box {
  border-radius: 2px;
  border: 1px solid var(--separator);
  box-shadow: 5px 5px #b7b7b71a;
}

/*
  React PDF
*/

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}

/*
  Form input autocomplete suggestions
*/

.autocomplete-wrapper {
  position: relative;
  width: 300px;
  background-color: var(--bg-primary);
}

.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid var(--separator);
  background-color: var(--bg-primary);
  list-style: none;
  padding: 0;
  margin: 0;
}

.suggestions-list li {
  padding: 8px;
  cursor: pointer;
  color: var(--text-primary);
}

.suggestions-list li:hover {
  background-color: var(--bg-secondary);
}

/*
  Multiselect
*/

// .icon_down_dir {
//   fill: var(--text-primary)!important;
// }

span.icon_down_dir {
  background-repeat: no-repeat;
  background-position: center;
  width: 18px;
  height: 18px;
}

span.icon_down_dir:before {
  content: "";
}

/*
  File dropzone
*/

.file-dropzone {
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 30px;
  border-width: 2px;
  border-radius: 4px;
  border-color: var(--separator);
  border-style: dashed;
  background-color: transparent;
  color: var(--text-secondary);
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;
};

/*
  Markdown
*/

.reactMarkDown p {
  margin-bottom: 0.8rem;
}

.reactMarkDown h1 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.reactMarkDown h2 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.reactMarkDown h3 {
  font-size: 0.95rem;
  margin-bottom: 0.5rem;
}

.reactMarkDown h4 {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.reactMarkDown h5 {
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
}

.reactMarkDown h6 {
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
}

/*
  React Switch
*/

.react-switch-handle {
  height: 12px;
  width: 12px;
}

/*
  Quill
*/

.ql-editor p {
  font-size: 0.85rem;
}

.ql-editor h1 {
  font-family: 'Inter Bold', sans-serif;
  font-size: 1.5rem;
  letter-spacing: -0.5px;
}

.ql-editor h2 {
  font-family: 'Inter Bold', sans-serif;
  font-size: 1.3rem;
  letter-spacing: -0.5px;
}

.ql-editor h3 {
  font-family: 'Inter Bold', sans-serif;
  font-size: 1rem;
  letter-spacing: -0.5px;
}

.ql-editor h4 {
  font-family: 'Inter Bold', sans-serif;
  font-size: 1rem;
  letter-spacing: -0.5px;
}

.ql-editor h5 {
  font-family: 'Inter Bold', sans-serif;
  font-size: 0.9rem;
}

.ql-editor h6 {
  font-family: 'Inter Bold', sans-serif;
  font-size: 0.7rem;
  text-transform: uppercase;
}

.ql-editor ol {
  margin-top: 5px!important;
}

.ql-editor ul {
  margin-top: 5px!important;
}

.ql-editor li {
  font-size: 0.85rem;
  margin-top: 10px!important;
}

.ql-editor a {
  color: var(--link-primary)!important;
  background-color: var(--bg-secondary);
  padding: 2px 4px;
  cursor: pointer;
}

.ql-editor .ql-code-block-container {
  font-family: 'Roboto Mono', monospace;
  color: var(--text-primary);
  background-color: var(--bg-secondary);
  padding: 20px;
  margin-bottom: 10px;
}

.ql-editor blockquote {
  font-size: 0.85rem;
  color: var(--text-primary);
  border-left: 4px solid var(--separator)!important;
}

.ql-container {
  border: none!important;
}

.ql-toolbar {
  border-left: none!important;
  border-right: none!important;
  text-align: center;
  // for ql snow theme:
  border-top: 1px solid var(--separator)!important;
  border-bottom: 1px solid var(--separator)!important;
  margin-top: 10px;
}

.ql-toolbar .ql-stroke {
  fill: none;
  stroke: var(--text-primary);
}

.ql-toolbar .ql-fill {
  fill:  var(--text-primary);
}

.ql-toolbar .ql-even {
  fill: none;
  stroke: var(--text-primary);
}

.ql-toolbar .ql-picker {
  color:  var(--text-primary);
}

.ql-toolbar .ql-picker-label {
  color:  var(--text-primary);
}

.ql-toolbar .ql-picker-options {
  background-color: var(--bg-primary);
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border: 1px solid var(--separator);
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border: 1px solid var(--separator);
}

.ql-toolbar .ql-picker-item {
  background-color: var(--bg-primary);
}

.ql-snow .ql-tooltip {
  font-size: 0.85rem;
  color: var(--text-primary);
  background-color: var(--bg-primary);
  border: 1px solid var(--separator)!important;
  box-shadow: none;
  padding: 8px 20px;
  border-radius: 20px;
}

.ql-snow .ql-tooltip a {
  color: var(--link-primary);
}

.ql-preview {
  font-size: 0.85rem;
}

.ql-snow input {
  border: 1px solid var(--separator)!important;
}

// .ql-editor .ql-code-block {
//   font-family: 'Roboto Mono', monospace;
// }

/*
  HTML2Canvas
*/

.exportpdf {
  font-family: 'Inter Regular', sans-serif;
  padding: 10px;
}

/*
  Tiptap
*/


.tiptap {
  outline: none;
}

// .tiptap pre {
//   padding: 10px;
//   margin: 5px;
//   background-color: var(--bg-secondary);
// }

// .tiptap code {
//   color: var(--text-primary);
// }

.tiptap-pre {
  background-color: var(--bg-primary-dark);
  border-radius: 0.5rem;
  color: var(--text-primary-dark);
  font-family: monospace;
  margin: 1.5rem 0;
  padding: 0.75rem 1rem;

  code {
    background: none;
    color: inherit;
    font-size: 0.8rem;
    padding: 0;
  }

  /* Code styling */
  .hljs-comment,
  .hljs-quote {
    color: #616161;
  }

  .hljs-variable,
  .hljs-template-variable,
  .hljs-attribute,
  .hljs-tag,
  .hljs-name,
  .hljs-regexp,
  .hljs-link,
  .hljs-name,
  .hljs-selector-id,
  .hljs-selector-class {
    color: #f98181;
  }

  .hljs-number,
  .hljs-meta,
  .hljs-built_in,
  .hljs-builtin-name,
  .hljs-literal,
  .hljs-type,
  .hljs-params {
    color: #fbbc88;
  }

  .hljs-string,
  .hljs-symbol,
  .hljs-bullet {
    color: #b9f18d;
  }

  .hljs-title,
  .hljs-section {
    color: #faf594;
  }

  .hljs-keyword,
  .hljs-selector-tag {
    color: #70cff8;
  }

  .hljs-emphasis {
    font-style: italic;
  }

  .hljs-strong {
    font-weight: 700;
  }
}

.tiptap-image {
  width: 100%;
}

.tiptap-link {
  // color: var(--accent);
  // background-color: var(--hue-primary);
  // padding: 1px 4px;
  cursor: pointer;
}

.tiptap-table {
  display: block;
  table-layout: fixed;
  width: 100%;
  max-width: 100%;
  border-color: var(--separator);
}

.tiptap-blockquote {
  border-left: 2px solid var(--separator);
  padding-left: 10px;
  font-style: italic;
}

.tiptap-table-header {
  font-weight: bold;
  background-color: var(--bg-secondary);
}

.btn-tiptap-bubble-menu {
  border-radius: 1px;
  margin: 2px;
  color: var(--text-primary);
  background-color: var(--bg-secondary);
  border: none;
  outline: none;
}

.btn-tiptap-bubble-menu-active {
  border-radius: 1px;
  margin: 2px;
  color: var(--text-primary);
  background-color: var(--bg-primary);
  border: none;
  outline: none;
}

.btn-tiptap-bubble-menu-hidden {
  display: none;
}

.tippy-box {
  display: flex;
  padding: 3px;
  border-radius: 0.2rem;
  background-color: var(--bg-secondary);
  box-shadow: var(--separator);
}

/*
  Blocknote CSS variables override
*/

// .bn-default-styles {
//   background-color: var(--bg-primary);
// }

// .bn-block-outer::before {
//   border-width: 0px!important;
// }

// .bn-block-content {
//   font-size: 85%;
// }

// .bn-block-content a {
//   color: var(--link-primary);
//   background-color: var(--bg-secondary);
//   padding: 2px 4px;
// }

// .bn-default-styles a {
//   cursor: pointer;
// }

// .bn-default-styles p {
  // font-size: 85%;
  // line-height: 1.2rem;
  // margin-bottom: 0.2rem;
// }
